const MAX_COST = 9999;
const DIAGONAL_COST = Math.sqrt(2); // Accurate cost for diagonal movement
const STRAIGHT_COST = 1;

const PathfindingAlgorithm = {
  TRACE: "TRACE",
  MANHATTAN: "MANHATTAN",
  BFS: "BFS", // Breath First Search
};

class PathGenerator {
  constructor(start, end, map, algorithm = PathfindingAlgorithm.TRACE) {
    this.start = start;
    this.end = end;
    this.map = map;
    this.path = [];
    this.cellMap = new Map(); // A Map to store cells with their x,y as the key
    this.currentCell = {
      processed: 1,
      x: this.start.x,
      y: this.start.y,
      pathId: 0,
      cost: MAX_COST,
    };
    this.algorithm = algorithm;
  }

  setPathFindingAlgo(algorithm){
    this.algorithm = algorithm
  }
  isValidCell(x, y) {
    if (x < 0 || y < 0 || y >= this.map.length || x >= this.map[0].length) {
      return false;
    }
    return this.map[y][x] === 0; // Assuming 0 is a walkable cell
  }

  calculateDistance(x, y) {
    return Math.abs(x - this.end.x) + Math.abs(y - this.end.y);
  }

  calculateHeuristic(x, y) {
    // Octile distance
    const dx = Math.abs(x - this.end.x);
    const dy = Math.abs(y - this.end.y);
    return dx + dy + (DIAGONAL_COST - 2) * Math.min(dx, dy);
  }

  rebuildPath(endNode) {
    let path = [];
    let currentNode = endNode;
    while (currentNode) {
      path.unshift({ x: currentNode.x, y: currentNode.y });
      currentNode = currentNode.parent;
    }
    return path;
  }

  generate() {
    
    const cellComparison = (a, b) => {
      const costA = a.pathId + a.cost;
      const costB = b.pathId + b.cost;
      return costA - costB;
    };

    this.cellMap.set(`${this.start.x},${this.start.y}`, this.currentCell);

    try {
      if (this.algorithm === PathfindingAlgorithm.TRACE) {
        this.findPathTrace();
      } else if (this.algorithm === PathfindingAlgorithm.BFS) {
        this.findPathBFS();
      } else if (this.algorithm === PathfindingAlgorithm.MANHATTAN) {
        this.findPathManhattan();
      } else {
        throw new Error("Invalid pathfinding algorithm selected");
      }

      let endNode = this.cellMap.get(`${this.end.x},${this.end.y}`);
      if (endNode) {
        this.path = this.rebuildPath(endNode);
        console.log("Path found:", this.path);
      } else {
        console.log("No path found");
        return 1;
      }
    } catch (e) {
      console.log("An error occurred:", e);
      return 1;
    }

    return 0; // Success
  }

  findPathBFS() {
    const queue = [this.currentCell];
    this.cellMap.set(
      `${this.currentCell.x},${this.currentCell.y}`,
      this.currentCell
    );

    while (queue.length > 0) {
      let current = queue.shift();

      if (current.x === this.end.x && current.y === this.end.y) {
        return; // Found the end
      }

      this.processNeighboursBFS(current, queue);
    }

    throw new Error("No path to end node");
  }

  
  findPathTrace() {
    const isEndNode = (cell) => cell.x === this.end.x && cell.y === this.end.y;

    while (true) {
      this.processNeighboursTrace();

      let unprocessedCells = Array.from(this.cellMap.values())
        .filter((cell) => !cell.processed)
        .sort((a, b) => a.cost + a.pathId - (b.cost + b.pathId));

      if (unprocessedCells.length === 0) {
        throw new Error("No path to end node");
      }

      this.currentCell = unprocessedCells[0];
      this.currentCell.processed = 1;

      if (isEndNode(this.currentCell)) {
        break;
      }
    }
  }
  findPathManhattan() {
    const isEndNode = (cell) => cell.x === this.end.x && cell.y === this.end.y;
  
    while (true) {
      this.processNeighboursManhattan();
  
      // Filtering and sorting based on cellMap
      let unprocessedCells = Array.from(this.cellMap.values())
        .filter((cell) => !cell.processed)
        .sort((a, b) => a.cost + a.pathId - (b.cost + b.pathId));
  
      if (unprocessedCells.length === 0) {
        console.log(this.map)
        console.log(this.start)
        console.log(this.end)
        console.log(this.cellMap)
        throw new Error("No path to end node");
      }
  
      this.currentCell = unprocessedCells[0];
      this.currentCell.processed = 1;
  
      if (isEndNode(this.currentCell)) {
        break;
      }
    }
  }  
  

  processNeighboursBFS(current, queue) {
    const directions = [
      [-1, 0],
      [1, 0],
      [0, -1],
      [0, 1], // Straight movements
      [-1, -1],
      [-1, 1],
      [1, -1],
      [1, 1], // Diagonal movements
    ];

    directions.forEach(([dx, dy]) => {
      let newX = current.x + dx;
      let newY = current.y + dy;

      if (
        !this.isValidCell(newX, newY) ||
        this.cellMap.has(`${newX},${newY}`)
      ) {
        return;
      }

      let nextCell = {
        x: newX,
        y: newY,
        parent: current, // We're coming to this cell from the current one
      };

      queue.push(nextCell);
      this.cellMap.set(`${newX},${newY}`, nextCell);
    });
  }

  processNeighboursManhattan() {
    const directions = [
      [-1, 0],
      [1, 0],
      [0, 1],
      [0, -1],
      [-1, -1],
      [1, -1],
      [-1, 1],
      [1, 1],
    ];

    directions.forEach(([dx, dy]) => {
      let posX = this.currentCell.x + dx;
      let posY = this.currentCell.y + dy;

      if (
        this.isValidCell(posX, posY) &&
        !this.cellMap.has(`${posX},${posY}`)
      ) {
        let costFactor =
          Math.abs(dx) === Math.abs(dy) ? DIAGONAL_COST : STRAIGHT_COST;
        let item = {
          parent: this.currentCell,
          processed: 0,
          x: posX,
          y: posY,
          pathId: this.currentCell.pathId + costFactor,
          cost: this.calculateDistance(posX, posY),
        };
        this.cellMap.set(`${posX},${posY}`, item);
      }
    });
  }

  processNeighboursTrace() {
    const directions = [
      [-1, 0],
      [1, 0],
      [0, -1],
      [0, 1], // Straight movements
      [-1, -1],
      [-1, 1],
      [1, -1],
      [1, 1], // Diagonal movements
    ];

    const turnPenalty = 0.5; // Additional cost for making a turn

    directions.forEach(([dx, dy]) => {
      let posX = this.currentCell.x + dx;
      let posY = this.currentCell.y + dy;

      if (
        this.isValidCell(posX, posY) &&
        !this.cellMap.has(`${posX},${posY}`)
      ) {
        let gCost = this.currentCell.pathId;
        let hCost = this.calculateHeuristic(posX, posY);
        let costFactor =
          Math.abs(dx) === Math.abs(dy) ? DIAGONAL_COST : STRAIGHT_COST;

        // Check for turn
        if (this.currentCell.parent) {
          let parentDirectionX = this.currentCell.x - this.currentCell.parent.x;
          let parentDirectionY = this.currentCell.y - this.currentCell.parent.y;

          // If the direction has changed, add a turn penalty
          if (dx !== parentDirectionX || dy !== parentDirectionY) {
            gCost += turnPenalty;
          }
        }

        let item = {
          parent: this.currentCell,
          processed: 0,
          x: posX,
          y: posY,
          pathId: gCost + costFactor,
          cost: hCost,
        };

        this.cellMap.set(`${posX},${posY}`, item);
      }
    });
  }
}

export default PathGenerator;
